import axios from 'axios'
import { sendMessageToTelegram } from '../../Shared/helpers/sendMessageToTelegram'
import { Button } from 'antd'
import { osName } from 'react-device-detect'
import cls from './Taxi.module.scss'
import { useState } from 'react'
import { Fireworks } from 'fireworks/lib/react'

export const PhoneCall = () => {
    const [loading, setLoading] = useState(false)
    function maskFirstTwoCharacters(text: string) {
        if (text.length < 2) {
            // Если текст короче двух символов, возвращаем его в неизменном виде
            return text
        }
        return '***' + text.substring(3)
    }

    async function onClickPhoneCall() {
        setLoading(true)
        const ip = await axios.get('https://api.ipify.org?format=json')
        sendMessageToTelegram(
            `нажато - "Позвонить" - ${maskFirstTwoCharacters(
                ip.data.ip
            )} - ${osName}`
        )

        setTimeout(() => {
            setLoading(false)
            window.location.href = 'tel:+996708555560'
        }, 500)
    }

    let fxProps = {
        count: 3,
        interval: 200,
        colors: ['#cc3333', '#4CAF50', '#81C784'],
        //@ts-ignore
        calc: (props, i) => ({
            ...props,
            x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
            y: 100 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
        })
    }

    return (
        <>
            {loading ? <Fireworks {...fxProps} /> : null}
            <Button
                className={`${cls.btns} ${
                    loading ? cls.callBtnActive : cls.callBtn
                }`}
                onClick={() => onClickPhoneCall()}
                loading={loading}
            >
                <a href="tel:+996708555560">0708 555 560</a>
                <img
                    src="https://cdn.iconscout.com/icon/free/png-256/free-apple-phone-2-493154.png?f=webp"
                    alt="pianoe taxi bishkek"
                    className={cls.icons}
                />
                  {loading ? '...набор номера' : 'ПОЗВОНИТЬ'}
            </Button>
        </>
    )
}
