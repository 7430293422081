import { Layout } from 'antd'
import './style/index.scss'
import { useEffect } from 'react'
import { Taxi } from '../Pages/Taxi/Taxi'
import Snowfall from 'react-snowfall'

function App() {
    function checkVisitsAndUpdateStatus() {
        //@ts-ignore
        const visits = JSON.parse(localStorage.getItem('visits')) || []
        const now = new Date().getTime()

        // Очищаем записи старше 24 часов
        const updatedVisits = visits.filter(
            (visit: any) => now - visit < 24 * 60 * 60 * 1000
        )

        // Добавляем текущий визит
        updatedVisits.push(now)

        // Обновляем записи в localStorage
        localStorage.setItem('visits', JSON.stringify(updatedVisits))
        localStorage.setItem('visitCount', updatedVisits.length.toString())
    }

    useEffect(() => {
        document.addEventListener(
            'DOMContentLoaded',
            checkVisitsAndUpdateStatus
        )
        return () =>
            document.removeEventListener(
                'DOMContentLoaded',
                checkVisitsAndUpdateStatus
            )
    }, [])

    return (
        <div className="App">
            <div className="titles">
                <h1>Пьяное такси бишкек</h1>
                <h1>Трезвый водитель такси бишкек</h1>
                <p>Трезвый водитель такси бишкек</p>
            </div>

            <Layout style={{ backgroundColor: 'transparent' }}>
                <Taxi />
            </Layout>
        </div>
    )
}

export default App
