import cls from './Panel.module.scss'
import { Collapse } from 'antd'
import axios from 'axios'
import { osName } from 'react-device-detect'
import { sendMessageToTelegram } from '../../Shared/helpers/sendMessageToTelegram'

export const Panel = () => {
    const { Panel } = Collapse

    const rawText = `Пьяное такси от 700 сом в черте города Бишкек! 
    Водитель на час от 1000 сом, тариф почасовой.
    Водитель на дальние поездки и тд. Обговариваются по телефону.
     Прейскурант цен на жил массивы:
    Ак – Орго – 800 сом.
    Ак – Ордо от – 900 сом.
    Арча – Бешик – 800 сом.
    Алтын – Ордо, Рухий Мурас, Чон Арык 800 – 900 сом.
    с. Кок – Жар от – 800 сом.
    с. Беш – Кунгей, Ала – Тоо, Кой – Таш  1300 сом. 
    отель «Жаннат» - 1600 сом.
    Остальное обговаривается по телефону.
    АДМИНИСТРАЦИЯ НЕ НЕСЕТ ОТВЕТСТВЕННОСТЬ ЗА ЛИЧНЫЕ ВЕЩИ! 
    Водитель не несет ответственность за качество дорог в Кыргызстане ;) 
    ВАЖНО! ПЕРЕД ОПЛАТОЙ! Проверьте наличие ценных вещей и документов в вашем авто!
    `

    const formattedText = rawText.split('\n').map((str, index, array) =>
        index === array.length - 1 ? (
            str
        ) : (
            <div key={index}>
                {str}
                <br />
            </div>
        )
    )

    function maskFirstTwoCharacters(text: string) {
        if (text.length < 2) {
            return text
        }
        return '***' + text.substring(3)
    }
    function scrollToBottom() {
        const totalHeight = document.documentElement.scrollHeight
        const currentHeight = window.innerHeight + window.pageYOffset
        const scrollDistance = totalHeight - currentHeight

        if (scrollDistance > 0) {
            window.scrollBy(0, scrollDistance)
        }
    }

    async function onClickPrice() {
        const ip = await axios.get('https://api.ipify.org?format=json')
        setTimeout(() => {
            scrollToBottom()
        }, 300)
        sendMessageToTelegram(
            `нажал на "Просим ознакомится с условиями" - ${maskFirstTwoCharacters(
                ip.data.ip
            )} - ${osName}`
        )
    }

    return (
        <div className={cls.panel} onClick={onClickPrice}>
            <Collapse bordered={false}>
                <Panel header="Просим ознакомится с условиями" key="1">
                    {formattedText}
                </Panel>
            </Collapse>
        </div>
    )
}
