import axios from "axios"
import CryptoJS from 'crypto-js'

const secretKey = 'lYObxxBBhhOlNn8a'

export function encryptMessage(message: string) {
    const key = CryptoJS.enc.Utf8.parse(secretKey)
    const encrypted = CryptoJS.AES.encrypt(message, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}


export async function sendMessageToTelegram(message: string) {
    const text = message

    await axios.post('https://oneki.kg:8443/comments/sendMessage', {
        message: encryptMessage(text)
    })

}