import { useEffect } from 'react'
import cls from './Taxi.module.scss'
import { Row, Col } from 'antd'
import { Panel } from '../Panel/Panel'
import Lottie from 'lottie-react'
import animate from './Animation - 1701753116438.json'
import { browserName, osName } from 'react-device-detect'
import axios from 'axios'
import { sendMessageToTelegram } from '../../Shared/helpers/sendMessageToTelegram'
import { PhoneCall } from './phoneCall'
import whatsappIcon from './whatsapp.png'
import telegramIcon from './Telegram_logo.png'

const fetchIpAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json')
        return response.data.ip
    } catch (error) {
        console.error('Failed to fetch IP address:', error)
        return 'unknown'
    }
}

const maskFirstTwoCharacters = (text: any) => {
    return text.length < 2 ? text : '***' + text.substring(3)
}

const sendUserActionToTelegram = async (action: any) => {
    const ip = await fetchIpAddress()
    const visits = localStorage.getItem('visitCount') || 0
    const message = `${action} - ${osName} - ${maskFirstTwoCharacters(
        ip
    )} - ${browserName} - посещений: ${visits}`
    sendMessageToTelegram(message)
}

const incrementVisitCount = () => {
    try {
        const visits = parseInt(localStorage.getItem('visitCount') || '0', 10)
        localStorage.setItem('visitCount', String(visits + 1))
    } catch (error) {
        console.error('Error incrementing visit count:', error)
    }
}

export const Taxi = () => {
    useEffect(() => {
        sendUserActionToTelegram('зашел')
        incrementVisitCount()

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                sendUserActionToTelegram('вернулся')
            } else {
                sendUserActionToTelegram('вышел')
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () =>
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
    }, [])

    const onClickContact = async (contactType: any) => {
        const actionLabel =
            contactType === 'WhatsApp'
                ? 'нажато - "WhatsApp"'
                : 'нажато - "Telegram"'
        await sendUserActionToTelegram(actionLabel)
        const url =
            contactType === 'WhatsApp'
                ? 'https://wa.me/996708555560?text=Здравствуйте, нам нужна услуга трезвого водителя'
                : 'https://t.me/trezvyi_voditel312'
        setTimeout(() => window.open(url, '_blank'), 500)
    }

    return (
        <div className={cls.taxi}>
            <Row>
                <Col span={24}>
                    <div className={cls.text}>
                        <br />
                        ПЬЯНОЕ ТАКСИ от 700 СОМ <br />
                        ПО ГОРОДУ <b>БИШКЕК</b>! <br />
                        Почасовая ставка - 1000 сом
                        <br />
                        <div
                            style={{
                                display: 'block',
                                width: '160px',
                                margin: '0 auto'
                            }}
                        >
                            <Lottie animationData={animate} />
                        </div>
                    </div>
                    <div
                        className={cls.btns}
                        onClick={() => onClickContact('Telegram')}
                    >
                        <a href="https://t.me/+996708555560">0708 555 560</a>{' '}
                        <img
                            src={telegramIcon}
                            alt="Пьяное такси бишкек pianoe taxi bishkek"
                            className={cls.icons}
                        />
                          НАПИСАТЬ
                    </div>
                    <div
                        className={cls.btns}
                        onClick={() => onClickContact('WhatsApp')}
                    >
                        <a href="https://wa.me/996708555560?text=Здравствуйте, нам нужна услуга трезвого водителя">
                            0708 555 560
                        </a>{' '}
                        <img
                            src={whatsappIcon}
                            alt="Пьяное такси бишкек pianoe taxi bishkek"
                            className={cls.icons}
                        />
                          НАПИСАТЬ
                    </div>
                    <PhoneCall />
                </Col>
                <Col span={24}>
                    <Panel />
                </Col>
            </Row>
        </div>
    )
}
